import  React ,{ useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BesView from './BesView';


export default function BesViewPrev() {

    const [hasChange,setHasChange] = useState( false ) ;

    const [show,setShow] = useState( false ) ;

    const [iniDate,setIniDate] = useState( "") ;

    function onChange2()
    {

        if ( !hasChange )
        {
            console.log( "No se ha seleccionado fecha") ;
            return ;
        }
 
        var v = document.getElementsByClassName('MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2')[0].value ;
                                              // MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2
                                              // MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-nxo287-MuiInputBase-input-MuiOutlinedInput-input
        console.log( v )

        setIniDate( v ) ;

        setShow(true ) ;

    }

    function setTheChange()
    {

        setHasChange( true ) ;

    }

  return (

    <>

    <div className='row pt-5'>

    <div className='col-4'></div>

    <div  className='col-4'  >

        <div className=' text-center' >

        

        <div style={{display:'inline-block'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer   components={['DatePicker']}>
                <DatePicker onChange={setTheChange} label="Fecha Inicio BesCustomer" />
            </DemoContainer>
            </LocalizationProvider>    

        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div className='col-1' style={{display:'inline-block'}}>
            <FontAwesomeIcon  onClick={onChange2} className='pt-2' style={{height:'25px'}} icon={faMagnifyingGlass} />

        </div>
        
        </div>

    </div>

    
    </div>

    { show && (

        <div className='pt-2'>
            <BesView iniDate={iniDate}></BesView>
        </div>

        

        )

    }



    </>
  );
}